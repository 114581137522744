import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {Projects} from "../../Projects";
const SingleProject = () => {
    const { t, i18n  } = useTranslation();
    const [imgs, setImgs] = useState([]);  
    const [text, setText] = useState("");  
    const {id} = useParams();  
    useEffect(() => {    
    for(let i = 0; i < Projects.length; i++) {      
        const obj = Projects[i];      
        if(obj.url === id){                
            setText(obj)  
            setImgs(obj.images); 
        }
    }  
    }, [id]); 
    return ( 
        <>
            <div className="container mt-5">
                <h1 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black'}>{t(text.title)}</span></h1>
            </div>
            <div className="container">
                <div className="row">
                    {imgs.map((value, index) => 
                    {      
                        return (
                            <div className="col-lg-3 col-md-4 col-6 py-2">
                                <img key={index} className="img-fluid img-thumbnail d-block" src={value} alt={t(text.title)}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
     );
}
 
export default SingleProject;