import logo from '../../images/WebLogo.png';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const NotFound = () => {
    const { t, i18n  } = useTranslation();
    return ( 
        <div className="container border border-primary mt-5 py-5">
            <img src={logo} alt="" className="nav-logo d-block mx-auto" />
            <h2 className="text-center"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("no_404")}</span></h2>
            <h1 className="text-center"><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black'}>{t("not_found")}</span></h1>
            <p className="text-center"><span className={i18n.language === 'en' ?  'montserrat-regular': 'cairo-regular'}>{t("not_found_desc")}</span></p>
            <center><div>
                <Link to='/'><button type="button" className="btn habout-btn mx-2"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("home_btn")}</span></button></Link>
                <Link to='/contact'><button type="button" className="btn habout-btn mx-2"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("contact_us")}</span></button></Link>
            </div></center>
        </div>
     );
}
 
export default NotFound;