import { useTranslation } from "react-i18next";
import ProjectsList from './ProjectsList';
const ProjectList = () => {
    const { t, i18n  } = useTranslation();
    return ( 
        <>
        <div className="container mt-3">
            <h1 className="text-info mt-5 text-center"><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black'}>{t("projects")}</span></h1>
        </div>
        <div className="container">
            <div className="row">
                <ProjectsList />
            </div>
        </div>
        </>
     );
}
 
export default ProjectList;