import { Routes, Route } from 'react-router-dom';
import Header from './components/layout/Header';
import Menu from './components/layout/Menu';
import Carousel from './components/home/Carousel';
import HAbout from './components/home/HAbout';
import HValues from './components/home/HValues';
import HServices from './components/home/HServices';
import HProjects from './components/home/HProjects';
import About from './components/pages/About';
import Services from './components/pages/Services';
import Contact from './components/pages/Contact';
import Footer from './components/layout/Footer';
import { useTranslation } from 'react-i18next';
import ProjectList from './components/pages/ProjectList';
import SingleProject from './components/pages/SingleProject';
import NotFound from './components/pages/NotFound';
// import Signup from './components/pages/Signup';
// import SignIn from './components/pages/SignIn';
// import CreateProject from './components/pages/CreateProject';
function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <div className="app">
      <Header />
      <Menu />
      <Routes>
        <Route path='/' element={<><Carousel /> <HAbout /> <HValues /> <HServices /> <HProjects /></>} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Services />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/projects' element={<ProjectList />} />
        <Route path='*' element={<NotFound />}/>
        <Route path='/projects/:id' element={<SingleProject />} />
        {/* <Route path='/admin-login' element={<SignIn />} /> */}
        {/* <Route path='/admin-createproject' element={<CreateProject />} /> */}
        {/* <Route path='/admin-signuplone' element={<Signup />} /> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
