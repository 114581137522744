import { useState } from 'react';
import Hamburger from 'hamburger-react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const Menu = () => {
    const [isOpen, setOpen] = useState(false);
    const handleToggle = () => {
        if(!isOpen){
            setOpen(true);
        }
        else {
            setOpen(false);
        }
    }
    const handleClick = () => {
        setOpen(false);
    }
    const { t, i18n } = useTranslation();
    const handleTrans = () => {
        if(i18n.language === 'en') {
            i18n.changeLanguage('ar');
        }
        else {
            i18n.changeLanguage('en');
        }
    };
    return ( 
        <>
        <nav className="navbar navbar-expand-lg bg-primary justify-content-center">
            <div className="tabs d-none d-lg-block">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <NavLink to="/" className='btn'><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("home")}</span></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/about" className='btn'><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("about")}</span></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/projects" className='btn'><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("projects")}</span></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/services" className='btn'><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("services")}</span></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/contact" className='btn'><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("contact")}</span></NavLink>
                    </li>
                </ul>
            </div>
            <i onClick={() => handleTrans()} className={i18n.language === 'en' ?  'ri-global-line text-secondary ps-5 h4': 'ri-global-line text-secondary pe-5 h4'}><span className={i18n.language === 'en' ?  'text-white montserrat-light': 'text-white cairo-light'}>{i18n.language === 'en' ?  'ع': 'En'}</span></i>
            <div className="hamburger d-lg-none">
                <Hamburger onToggle={handleToggle} toggled={isOpen} toggle={setOpen} color="#d6ac5b" />
            </div>
            
        </nav>
        <div className="mobile-nav d-lg-none">
        {isOpen && 
            <div className="container-fluid bg-primary">
                <ul className="navbar-nav">
                    <li className="mob-nav-item">
                        <NavLink to="/" onClick={handleClick}><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("home")}</span></NavLink>
                    </li>
                    <li className="mob-nav-item">
                        <NavLink to="/about" onClick={handleClick}><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("about")}</span></NavLink>
                    </li>
                    <li className="mob-nav-item">
                        <NavLink to="/projects" onClick={handleClick}><span className={i18n.language === 'en' ?  'montserrat-bold disabled': 'cairo-bold disabled'}>{t("projects")}</span></NavLink>
                    </li>
                    <li className="mob-nav-item">
                        <NavLink to="/services" onClick={handleClick}><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("services")}</span></NavLink>
                    </li>
                    <li className="mob-nav-item">
                        <NavLink to="/contact" onClick={handleClick}><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("contact")}</span></NavLink>
                    </li>
                </ul>
            </div>
        }
    </div>
    </>
     );
}
 
export default Menu;