import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.js';
import 'remixicon/fonts/remixicon.css'
import App from './App';
// Translation
import './i18n'
// Styles
import './styles/index.scss';
import './styles/carousel.scss';
import './styles/header.scss';
import './styles/habout.scss';
import './styles/hvalues.scss';
import './styles/hservices.scss';
import './styles/about.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter><App /></BrowserRouter>
  </React.StrictMode>
);

