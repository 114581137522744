import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      en: {
        translation: {
         site_title: "Summit Partners Contracting",
         location: "Riyadh, KSA",
         email: "admin@summitpartners-ksa.com",
         number: "0553355133 | 0534802656",
         home: "Home",
         about: "About",
         projects: "Projects",
         services: "Services",
         contact: "Contact",
         footer_about: "We’re always developing to be on the top as We’re keeping up with the renaissance that the Kingdom witnesses and that consistent with the 2030 Vision.",
         quick_links: "Quick Links",
         get_connected: "Get Connected",
         carousel1_header: "Professional Approach",
         carousel1_p: "We aim to provide a professional approach to build exceptional quality projects which meet established budgeting for each project and scheduiling",
         carousel2_header: "Innovative & Creative",
         carousel2_p: "We always reach the featured level in offering services with a great quality standards",
         view_projects: "View Projects",
         free_quote: "Get A Free Quote",
         we_are: "We are",
         innovative: "Innovative",
         creative: "Creative",
         about_us: "ABOUT US",
         about_us_desc: "We utilize our general contracting experience, design, and build capabilities to meet the needs of each project.",
         learn_more_us: "Learn more",
         values: "Values",
         values_desc: "Our experienced and dedicated staff provide these values with a smile.",
         poe: "Passion For Excellence",
         poe_desc: "To be the best, all the time.",
         cf: "Customer Focused",
         cf_desc: "We ensure every customer is satisfied.",
         teamwork: "Teamwork",
         teamwork_desc: "We promote an environment of collaboration and respect.",
         integrity: "Integrity",
         integrity_desc: "We are professional, ethical, honest and accountable.",
         our_services: "Our Services",
         quality: "Quality",
         speed: "Speed",
         follow_up: "Follow-up",
         precision: "Precision",
         guarantee: "Guarantee",
         architecture: "Architecture",
         steel_structure: "Steel Structure",
         decor: "Decor",
         free_quote_today: "Get a free quote today!",
         free_quote_desc: "LET’S TALK – LET’S PLAN – LET’S BUILD – LET’S WRAP UP",
         free_quote_btn: "Free Quote",
         full_about: "We are innovative and creative when it comes to our field We utilize our general contracting experience, design, and build capabilities to meet the needs of each project. We’re always developing to be on the top as We’re keeping up with the renaissance that the Kingdom witnesses and that consistent with the 2030 Vision.",
         our_approach: "Our Approach",
         our_approach_desc: "Our approach satisfies you and makes your journey easy.",
         quality_desc: "We execute every phase in the project perfectly to make sure you get the quality you deserve.",
         speed_desc: "Your time is valuable for us. We make sure the project is finished on time.",
         followup_desc: "You're part of our success. We make sure you are satisfied.",
         precision_desc: "We work in an efficient way to avoid any kind of mistakes.",
         guarantee_desc: "We always seek top level success. Your satisfaction defines our success.",
         our_services_desc: "We are committed to deliver you the best services.",
         contact_us: "Contact Us",
         contact_us_desc: "Let's get in touch",
         our_contact: "Our Contact",
         email_contact: "Email Address",
         phone_number: "Phone Number",
         our_location: "Location",
         name: "Name",
         name_desc: "Enter your name",
         your_email: "Email",
         your_email_desc: "Enter your email",
         your_phone: "Phone Number",
         your_phone_desc: "Enter your phone number",
         submit: "Submit",
         connect_with_us: "CONNECT WITH US!",
         your_password: "Password",
         your_password_desc: "Enter your password",
         login_msg: "You've successfully logged in. Please wait we're redirecting you.",
         login_error_msg: "You've entered a wrong email or password. Please try again.",
         logged_hello: "Hello",
         logged: "You are already logged in.",
         home_btn: "Go Back Home",
         logout_btn: "Logout",
         logged_out: "You've successfully logged out. We're redirecting you to the home page.",
         login_btn: "Login",
         your_msg: "Message",
         your_msg_desc: "Enter your message",
         sending: "Please wait, We're sending the message",
         sent: "Your message has been successfully sent",
         captcha_msg: "Confirm you aren't a robot by typing",
         missing: "You've to fill all the information",
         adding_projects: "We're working on adding our projects, We appreciate your patience.",
         Res: "Residential",
         Electric: "Electrical Work",
         Mechanic: "Mechanical Work",
         Pharma: "Pharmacies",
         no_404: "404",
         not_found: "Not Found",
         not_found_desc: "The page you are looking for was not found."

        }
      },
      ar: {
        translation: {
         site_title: "شركاء القمة للمقاولات",
         location: "الرياض, السعودية",
         email: "admin@summitpartners-ksa.com",
         number: "٠٥٥٣٣٥٥١٣٣ | ٠٥٣٤٨٠٢٦٥٦",
         home: "الصفحة الرئيسية",
         about: "عن الشركة",
         projects: "المشاريع",
         services: "الخدمات",
         contact: "اتصل بنا",
         footer_about: "نعمل على التطور المستمر دائما لنكـــون على القمة لمواكبة النهضة التي تشهدها المملكة و التي تتفق مع رؤية 2030.",
         quick_links: "تصفح الموقع",
         get_connected: "تواصل معنا",
         carousel1_header: "نهج احترافي",
         carousel1_p: "هدفنا هو توفير نهج احترافي لبناء مشاريع ذات جودة استثنائية تلبي الميزانية المحددة لكل مشروع وجدولة الأهداف",
         carousel2_header: "مبتكرون و مبدعون",
         carousel2_p: "دائما نصل بشركتنا لمستوى متميز في تقديم الخدمات بأعلى معايير الجودة",
         view_projects: "المشاريع",
         free_quote: "احصل على تسعيرة مجانية",
         we_are: "نحن",
         innovative: "مبتكرون",
         creative: "مبدعون",
         about_us: "من نحن",
         about_us_desc: "نستخدم خبرتنا وقدرتنا في المقاولات والتصميم والبناء لتلبية احتياجات كل مشروع.",
         learn_more_us: "المزيد عنا",
         values: "القيم",
         values_desc: "يقدم موظفونا المتمرسون و المتفانون هذه القيم بابتسامة.",
         poe: "شغف التميز",
         poe_desc: "ان نكون الأفضل طوال الوقت.",
         cf: "مركزية العميل",
         cf_desc: "ضمان رضا وسعادة كل عميل.",
         teamwork: "العمل الجماعي",
         teamwork_desc: "نعمل في بيئة يملأها التعاون والاحترام.",
         integrity: "النزاهة",
         integrity_desc: "نعمل باحترافية و أخلاق و صدق ومسؤولية.",
         our_services: "خدماتنا",
         quality: "الجودة",
         speed: "السرعة",
         follow_up: "المتابعة",
         precision: "الدقة",
         guarantee: "الضمان",
         architecture: "معماري",
         steel_structure: "انشائي",
         decor: "ديكور",
         free_quote_today: "احصل على تسعيرة مجانية اليوم!",
         free_quote_desc: "نتحدث - نخطط - نبني - ننجح سويا",
         free_quote_btn: "تسعيرة مجانية",
         full_about: "نحن شركاء القمة مبتكرون ومبدعون عندما يتعلق الأمـر بمجالنا نستخدم خبرتنا وقدرتنا في المـقاولات و التصميـم و البناء لتلبية احتياجات كل مشـروع و نعمل على التطور المستمر دائما لنكـون على القمة لمواكبة النهضة التي تشهدها المملكة و التي تتفق مع رؤية 2030.",
         our_approach: "نهجنا",
         our_approach_desc: "نهجنا يرضيك و يجعل رحلتك سهلة.",
         quality_desc: "نقوم بتنفيذ كل مرحلة في المشروع بشكل مثالي للتأكد من حصولك على الجودة التي تستحقها.",
         speed_desc: "وقتك ثمين بالنسبة لنا. نتأكد دائما من انتهاء المشروع في الوقت المحدد.",
         followup_desc: "أنت جزء من نجاحنا. نتأكد دائما من رضاك.",
         precision_desc: "نعمل بطريقة فعالة لتجنب أي نوع من الأخطاء.",
         guarantee_desc: "نسعى دائمًا لتحقيق أعلى مستوى من النجاح. رضاك يحدد نجاحنا.",
         our_services_desc: "نحن ملتزمون بتقديم أفضل الخدمات لك.",
         contact_us: "الأتصال بنا",
         contact_us_desc: "لنتواصل معا",
         our_contact: "ارسل لنا من خلال",
         email_contact: "البريد الألكتروني",
         phone_number: "اتصل علينا",
         our_location: "الموقع",
         name: "الأسم",
         name_desc: "ادخل اسمك",
         your_email: "البريد الألكتروني",
         your_email_desc: "ادخل بريدك الألكتروني",
         your_phone: "رقم الجوال",
         your_phone_desc: "ادخل رقم جوالك",
         submit: "ارسل",
         connect_with_us: "تواصل معنا",
         your_password: "كلمة المرور",
         your_password_desc: "ادخل كلمة المرور",
         login_msg: "تم تسجيلك بنجاح, برجاء الانتظار جاري تحويلك للصفحة الرئيسية",
         login_error_msg: "لقد ادخلت بريد الكتروني او كلمة مرور خطأ. يرجى التأكد والمحاولة مرة اخرى.",
         logged_hello: "اهلا",
         logged: "انت مسجل",
         home_btn: "الرجوع الى الصفحة الرئيسية",
         logout_btn: "تسجيل الخروج",
         logged_out: "لقم تم تسجيل خروجك بنجاح, برجاء الانتظار جاري تحويلك للصفحة الرئيسية",
         login_btn: "تسجيل الدخول",
         your_msg: "الرسالة",
         your_msg_desc: "ادخل الرسالة",
         sending: "برجاء الانتظار جاري الارسال",
         sent: "تم الارسال بنجاح",
         captcha_msg: "أكد انك لست روبوت بكتابة",
         missing: "يجب عليك ملأ كل البيانات",
         adding_projects: "نعمل على اضافة مشاريعنا, شكرا لتفهمك.",
         Res: "سكني",
         Electric: "أعمال كهرباء",
         Mechanic: "أعمال ميكانيكا",
         Pharma: "صيدليات",
         no_404: "٤٠٤",
         not_found: "غير متاح",
         not_found_desc: "الصفحة التي تبحث عنها غير موجودة."
        }
      },
    }
  });

export default i18n;