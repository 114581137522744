import cf from '../../images/cf.png';
import pfe from '../../images/pfe.png';
import teamwork from '../../images/teamwork.png';
import integrity from '../../images/integrity.png';
import { useTranslation } from "react-i18next";
const HValues = () => {
    const { t, i18n  } = useTranslation();
    return ( 
        <>
        <div className="container mt-5">
            <h1 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black'}>{t("values")}</span></h1>
            <p className="text-muted text-center"><span className={i18n.language === 'en' ?  'montserrat-regular': 'cairo-regular'}>{t("values_desc")}</span></p>
            <div className="row mt-5">
                <div className="col-12 col-md-6 col-lg-3 mb-5">
                    <img src={pfe} alt="Passion For Excellence - شغف التميز" className="value-img mx-auto d-block"/>
                    <h4 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-semibold': 'cairo-semibold'}>{t("poe")}</span></h4>
                    <p className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("poe_desc")}</span></p>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-5">
                    <img src={cf} alt="Customer Focused - مركزية العميل" className="value-img mx-auto d-block"/>
                    <h4 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-semibold': 'cairo-semibold'}>{t("cf")}</span></h4>
                    <p className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("cf_desc")}</span></p>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-5">
                    <img src={teamwork} alt="Teamwork - العمل الجماعي" className="value-img mx-auto d-block"/>
                    <h4 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-semibold': 'cairo-semibold'}>{t("teamwork")}</span></h4>
                    <p className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("teamwork_desc")}</span></p>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-5">
                    <img src={integrity} alt="Integrity - النزاهة" className="value-img mx-auto d-block"/>
                    <h4 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-semibold': 'cairo-semibold'}>{t("integrity")}</span></h4>
                    <p className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("integrity_desc")}</span></p>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default HValues;